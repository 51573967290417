import React, { useCallback } from 'react';
import animatedScroll from '../util/animated-scroll';

import '../styles/components/scroller.scss';

interface ScrollerProps {
  text?: string;
}

const Scroller: React.FC<ScrollerProps> = ({ text }) => {
  const scrollDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const nextSection = e.currentTarget.parentElement?.nextElementSibling;

      if (nextSection) {
        animatedScroll(nextSection);
      }
    },
    [animatedScroll],
  );

  return (
    <div className="scroller" onClick={scrollDown}>
      {text}
    </div>
  );
};

export default Scroller;

import classNames from 'classnames';
import React, { LinkHTMLAttributes } from 'react';

import BetterLink from '../util/link';

import '../styles/components/button.scss';

interface ButtonProps {
  dark?: any;
}

const Button = React.forwardRef<
  HTMLAnchorElement,
  LinkHTMLAttributes<any> & ButtonProps
>((props, ref) => (
  <BetterLink
    to={props.href || ''}
    {...props}
    className={classNames('btn', props.className, { dark: !!props.dark })}
    ref={ref}
  >
    {props.children}
  </BetterLink>
));

export default Button;

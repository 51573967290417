import React from 'react';

import { TimelineSlice as TimelineSliceProps } from '../../types/graphql';
import { removeOuterTags } from '../../util/html';

import { SliceProps } from './sliceprops';

import '../../styles/slices/timeline.scss';

export const TimelineSlice: React.FC<SliceProps<TimelineSliceProps>> = ({
  data: { timeline_entries, title },
}) => (
  <div className="container">
    <h2 dangerouslySetInnerHTML={{ __html: removeOuterTags(title?.html) ?? '' }} />

    <ul>
      {timeline_entries!.map((item, i) => (
        <li key={i}>
          <h3>
            {item.year}
            :&nbsp;
            <span
              dangerouslySetInnerHTML={{
                __html: removeOuterTags(item.title?.html) ?? '',
              }}
            />
          </h3>
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: item.body?.html ?? '',
            }}
          />
        </li>
      ))}
    </ul>
  </div>
);

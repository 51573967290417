import React, { useState } from 'react';
import {
  withGoogleMap,
  withScriptjs,
  WithScriptjsProps,
  WithGoogleMapProps,
} from 'react-google-maps';

import pin from '../../../static/images/map-pin.svg';
import { CustomMapSlice as CustomMapSliceProps } from '../../types/graphql';
import { mapOptions } from '../../util/map';

import { GoogleMap, InfoWindow, Marker } from '../../util/react-google-maps';
import { SliceProps } from './sliceprops';

import '../../styles/slices/custom-map.scss';

interface InfoMapProps {
  openInfoBoxId: number | null;

  onInfoWindowClose?: () => void;
  onOpenInfoWindow?: (id: number) => void;
}

const InfoMap = withScriptjs(
  withGoogleMap(
    ({ data: data_, openInfoBoxId, onInfoWindowClose, onOpenInfoWindow }) => {
      const { geopoints } = data_ as CustomMapSliceProps;

      return (
        <GoogleMap
          defaultCenter={{
            lat: geopoints[0].lat,
            lng: geopoints[0].lng,
          }}
          options={mapOptions}
          zoom={15}
        >
          {geopoints.map(({ body, lat, lng }, i) => (
            <Marker
              key={i}
              position={{ lat, lng }}
              icon={pin}
              onClick={() => onOpenInfoWindow(i)}
            >
              {openInfoBoxId === i && (
                <InfoWindow onCloseClick={onInfoWindowClose}>
                  <div
                    className="info-window"
                    dangerouslySetInnerHTML={{
                      __html: body?.html ?? '',
                    }}
                  />
                </InfoWindow>
              )}
            </Marker>
          ))}
        </GoogleMap>
      );
    },
  ),
) as React.ComponentType<
  React.PropsWithChildren<InfoMapProps & WithGoogleMapProps & WithScriptjsProps>
>;

export type CustomMapProps = SliceProps<CustomMapSliceProps>;

export const CustomMap: React.FC<CustomMapProps> = (props) => {
  const [openInfoBoxId, setOpenInfoBoxId] = useState<number | null>(null);

  return (
    <InfoMap
      containerElement={<div style={{ height: `100%` }} />}
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_MAPS_KEY}&v=3.exp&libraries=places&language=${props.lang}`}
      loadingElement={<div />}
      mapElement={<div style={{ height: `100%` }} />}
      onInfoWindowClose={() => setOpenInfoBoxId(null)}
      onOpenInfoWindow={setOpenInfoBoxId}
      openInfoBoxId={openInfoBoxId}
      {...props}
    />
  );
};

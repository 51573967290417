import classNames from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import Countdown from 'react-countdown';

import { CountdownSlice as CountdownSliceProps } from '../../types/graphql';
import { removeOuterTags } from '../../util/html';
import { getHref } from '../../util/link';
import Button from '../button';

import '../../styles/slices/countdown.scss';

import { SliceProps } from './sliceprops';

export const CountdownSlice: React.FC<SliceProps<CountdownSliceProps>> = ({
  data: { countdown_time, image, image_alt, links, title },
  lang,
}) => {
  const buttons = links!.filter(
    (item) =>
      item &&
      (item.label || item.rich_label?.html) &&
      (item.file?.publicURL || item.link || item.page_rel),
  );

  const showImage = Boolean(image?.childImageSharp);
  const showTitle = Boolean(title?.html);

  const countdownRenderer = ({
    formatted: { days, hours, minutes, seconds },
    completed,
  }) =>
    !completed ? (
      <span className="countdown">
        {days && days + ':'}
        {hours}:{minutes}:{seconds}
      </span>
    ) : null;

  return (
    <>
      {showImage && <div className="background" />}

      {showImage && (
        <GatsbyImage
          className="image"
          image={image?.childImageSharp?.gatsbyImageData}
          alt={image_alt || ''}
          imgStyle={{ objectFit: 'cover' }}
        />
      )}

      {showImage && showTitle ? <div className="overlay" /> : null}

      <div className={classNames('content', { dark: showImage })}>
        {title?.html && (
          <h2
            dangerouslySetInnerHTML={{ __html: removeOuterTags(title.html) ?? '' }}
          />
        )}

        {countdown_time && (
          <Countdown
            date={countdown_time.replace(/\+(\d+)$/, '')}
            renderer={countdownRenderer}
          />
        )}

        {buttons.length > 0 && (
          <div className="buttons">
            {buttons!.map((link, i) => (
              <Button
                key={i}
                href={getHref(link, lang) || '#'}
                dark={!showImage ? 'dark' : undefined}
                dangerouslySetInnerHTML={{
                  __html:
                    (link.rich_label?.html
                      ? removeOuterTags(link.rich_label.html)
                      : link.label) ?? '',
                }}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

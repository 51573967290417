import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, {
  useDeferredValue,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  AccessoriesQuery,
  AccessoryListSlice as AccessorySliceProps,
} from '../../types/graphql';
import { removeOuterTags } from '../../util/html';

import { SliceProps } from './sliceprops';

import alternatives from '../../../data/oven-alternatives.json';
import accessories from '../../../static/accessories/accessories.json';
import '../../styles/slices/accessory-list.scss';

interface AccessoryItemProps {
  articleId?: string | null;
  description?: string | null;
  descriptionHeight: number | null;
  descriptionRef: (el: HTMLDivElement) => void;
  lang: string;
  link?: string;
  thumbnail?: unknown | null;
  title?: string | null;
}

const accessoryQuery = graphql`
  query Accessories {
    allFile(
      filter: {
        sourceInstanceName: { eq: "accessories" }
        extension: { ne: "json" }
      }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: TRACED_SVG
            height: 290
            outputPixelDensities: [0.25, 0.5, 1, 2, 3]
          )
        }
        relativePath
      }
    }
  }
`;

const accessoriesKeyedById = accessories.reduce((acc, item) => {
  acc[item.article.trim()] = item;
  return acc;
}, {});

const AccessoryItem: React.FC<AccessoryItemProps> = ({
  articleId,
  description,
  descriptionHeight,
  descriptionRef,
  lang,
  link,
  thumbnail,
  title,
}) => {
  const artNo = lang.includes('de') ? 'Art.-Nr.' : 'Item No.';
  const inner = (
    <>
      <div className="image-container">
        {thumbnail ? (
          <GatsbyImage image={thumbnail as IGatsbyImageData} alt="" />
        ) : (
          <img src="" />
        )}
      </div>

      <div className="separator" />
      <h3>{title}</h3>

      {articleId && (
        <div className="description">
          {artNo} {articleId}
        </div>
      )}
      {description && (
        <div
          className="description"
          ref={descriptionRef}
          style={{ height: descriptionHeight !== null ? descriptionHeight : 'auto' }}
        >
          {description}
        </div>
      )}
    </>
  );

  return link ? (
    <a className="item" href={link}>
      {inner}
    </a>
  ) : (
    <div className="item">{inner}</div>
  );
};

export const AccessoryListSlice: React.FC<SliceProps<AccessorySliceProps>> = ({
  data: { body, products, title },
  lang,
}) => {
  const { allFile }: AccessoriesQuery = useStaticQuery(accessoryQuery);

  const itemsToShow = useMemo(() => {
    const productsToShow = new Set(products.map((uid) => alternatives[uid!] || uid));

    return allFile.nodes
      .filter((node) => {
        const [, productType] = node.relativePath.split('/').filter((part) => part);
        return productsToShow.size === 0 || productsToShow.has(productType);
      })
      .map(
        (accessoryFile) =>
          [accessoryFile, accessoriesKeyedById[accessoryFile.name.trim()]] as const,
      )
      .filter(([, accessoryMeta]) => accessoryMeta)
      .sort(([a], [b]) => (a.name > b.name ? 1 : a.name === b.name ? 0 : -1));
  }, [allFile.nodes, products]);

  // Make sure all items' descriptions have the same height
  const [height, setHeight] = useState<number | null>(null);
  const descriptionRef = useRef<HTMLDivElement[]>([]);

  // Use a deferred value to debounce style recalculation after resizes.
  //
  // The value here is an integer that's simply counted up whenever the browser
  // window resizes. We pass that through useDeferredValue to only recalculate the
  // styles when React is idle.
  const [_resize, setResize] = useState(0);
  const resize = useDeferredValue(_resize);

  useEffect(() => {
    const handler = () => setResize((v) => v + 1);
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, []);

  useLayoutEffect(() => {
    const heights = descriptionRef.current
      .filter((el) => el)
      .map((el) => {
        const h = el.style.height;
        el.style.height = 'auto';
        const measuredHeight = el.clientHeight;
        el.style.height = h;
        return measuredHeight;
      });

    if (heights.length > 0) {
      setHeight(Math.max(...heights));

      return () => setHeight(null);
    }
  }, [itemsToShow, resize]);

  return (
    <div className="container">
      {title?.html && (
        <h2
          dangerouslySetInnerHTML={{ __html: removeOuterTags(title.html) ?? '' }}
        />
      )}
      {body?.html && (
        <div
          className="subheading"
          dangerouslySetInnerHTML={{ __html: body.html }}
        />
      )}

      <div className="items">
        {itemsToShow.map(([file, description], i) => (
          <AccessoryItem
            key={i}
            articleId={description?.article}
            description={
              lang.includes('de') ? description?.info?.de : description?.info?.en
            }
            descriptionHeight={height}
            descriptionRef={(el) => (descriptionRef.current[i] = el)}
            lang={lang}
            thumbnail={file.childImageSharp?.gatsbyImageData}
            title={
              lang.includes('de') ? description?.name?.de : description?.name?.en
            }
          />
        ))}
      </div>
    </div>
  );
};

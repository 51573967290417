import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

import { SliceProps } from './sliceprops';

import { SplitListSlice as SplitListSliceProps } from '../../types/graphql';
import { removeOuterTags } from '../../util/html';

import checkmarkIcon from '../../../static/images/check-circle.svg';

import '../../styles/slices/split-list.scss';
import '../../styles/slices/playwright.scss';

export const SplitList: React.FC<SliceProps<SplitListSliceProps>> = ({
  data: { image, image_alt, split_list_entries, title, video },
}) => {
  const prefersReducedMotion =
    typeof window !== 'undefined' &&
    window.matchMedia('(prefers-reduced-motion)').matches;

  const hasImage = image?.childImageSharp?.gatsbyImageData;
  const hasVideo = video?.mp4?.publicURL || video?.webm?.publicURL; // need to have at least mp4 or webm

  const showVideo = prefersReducedMotion ? !hasImage : hasVideo;
  const showImage = hasImage && !showVideo;

  const showTitle = Boolean(title?.html);

  return (
    <div className="container">
      <div className="content">
        {showTitle && (
          <h2
            dangerouslySetInnerHTML={{ __html: removeOuterTags(title?.html) ?? '' }}
          />
        )}
        <ul>
          {split_list_entries?.map((item, index) => (
            <li key={index}>
              <img src={checkmarkIcon} alt="Checkmark" />
              <p
                dangerouslySetInnerHTML={{
                  __html: removeOuterTags(item.body?.html) ?? '',
                }}
              />
            </li>
          ))}
        </ul>
      </div>

      <div className="media-container">
        <div className="media">
          {showVideo && (
            <video
              autoPlay={!prefersReducedMotion}
              muted={true}
              loop={true}
              playsInline={true}
            >
              {video?.mp4.publicURL && (
                <source src={video.mp4.publicURL} type="video/mp4" />
              )}
              {video?.webm?.publicURL && (
                <source src={video.webm.publicURL} type="video/webm" />
              )}
              {video?.ogg?.publicURL && (
                <source src={video.ogg.publicURL} type="video/ogg" />
              )}
            </video>
          )}

          {showImage && (
            <GatsbyImage
              className="image"
              image={image.childImageSharp.gatsbyImageData}
              alt={image_alt || ''}
              imgStyle={{ objectFit: 'contain' }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

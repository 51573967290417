import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import sample from 'lodash-es/sample';

import { TwoWayBannerSlice as TwoWayBannerSliceProps } from '../../types/graphql';
import { getHref } from '../../util/link';
import Button from '../button';
import Scroller from '../scroller';

import { SliceProps } from './sliceprops';

import '../../styles/slices/two-way-banner.scss';

export const TwoWayBannerSlice: React.FC<SliceProps<TwoWayBannerSliceProps>> = ({
  data: { cta_link, cta_text, images, text },
  lang,
}) => {
  const [image] = useState(sample(images));
  const sharpImage = image?.image?.childImageSharp?.gatsbyImageData;

  return (
    <>
      <div className="banner-image">
        {sharpImage && (
          <GatsbyImage
            objectFit="cover"
            objectPosition="center"
            alt={image?.alt_text ?? ''}
            image={sharpImage}
          />
        )}
      </div>
      <div className="banner-content">
        <div
          className="text"
          dangerouslySetInnerHTML={{ __html: text?.html ?? '' }}
        />

        {cta_text && cta_link && (
          <Button
            href={getHref(cta_link, lang) ?? '#'}
            dangerouslySetInnerHTML={{
              __html: cta_text ?? '',
            }}
          />
        )}
      </div>
      <Scroller />
    </>
  );
};

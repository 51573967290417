import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

import { IconListSlice as IconListSliceProps } from '../../types/graphql';
import { removeOuterTags } from '../../util/html';
import { getHref } from '../../util/link';

import { SliceProps } from './sliceprops';

import '../../styles/slices/icon-list.scss';

export const IconListSlice: React.FC<SliceProps<IconListSliceProps>> = ({
  data: { icon_list_entries, title },
  lang,
}) => (
  <div className="container">
    <h2 dangerouslySetInnerHTML={{ __html: removeOuterTags(title?.html) ?? '' }} />

    <div className="icons">
      {icon_list_entries?.map((item, i) => (
        <a key={i} href={getHref(item.link, lang) || ''}>
          <GatsbyImage
            alt={item.alt ?? ''}
            image={item.icon?.childImageSharp?.gatsbyImageData}
            objectFit="contain"
          />
          <h3
            dangerouslySetInnerHTML={{
              __html: removeOuterTags(item.title?.html) ?? '',
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: item!.body?.html ?? '',
            }}
          />
        </a>
      ))}
    </div>
  </div>
);

import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { join } from 'path';
import React, { useEffect, useRef } from 'react';

import { ServiceSlice as ServiceSliceProps } from '../../types/graphql';
import animatedScroll from '../../util/animated-scroll';
import { removeOuterTags } from '../../util/html';
import makeLazy from '../../util/make-lazy';

import { SliceProps } from './sliceprops';

import '../../styles/slices/icon-list.scss';
import '../../styles/slices/service.scss';

const PublicService = makeLazy(() => import('../service/public'));

export const ServiceSlice: React.FC<SliceProps<ServiceSliceProps>> = ({
  basepath,
  data: {
    dealer_icon,
    dealer_icon_alt,
    dealer_text,
    dealer_title,
    hide_login_icon,
    lab_icon,
    lab_icon_alt,
    lab_text,
    lab_title,
    login_icon,
    login_icon_alt,
    login_text,
    login_title,
    subtitle,
    title,
  },
  lang,
}) => {
  const iconListRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLDivElement>(null);

  const handleScrollToForm = () => {
    if (formRef.current) {
      animatedScroll(formRef.current);
    }
  };

  useEffect(() => {
    window.setTimeout(() =>
      window.requestAnimationFrame(() => {
        const element = iconListRef.current;
        if (element) {
          animatedScroll(element);
        }
      }),
    );
  }, []);

  return (
    <>
      <section className="icon_list" ref={iconListRef}>
        <div className="container">
          <h2
            dangerouslySetInnerHTML={{ __html: removeOuterTags(title?.html) ?? '' }}
          />

          <p
            dangerouslySetInnerHTML={{
              __html: removeOuterTags(subtitle?.html) ?? '',
            }}
          />
        </div>
      </section>
      <section ref={formRef}>
        {/*
         * TODO: We do this dirty hack with window to trick gatsby into not rendering the
         * service statically, because that would require Suspense, which isn't available
         * in server-rendered contexts right now.
         */}
        {typeof window !== 'undefined' && (
          <PublicService basepath={basepath} lang={lang} />
        )}
      </section>
      <section className="icon_list">
        <div className="container">
          <div className="icons">
            <Link onClick={handleScrollToForm} to={join(basepath, 'register-lab')}>
              {lab_icon?.childImageSharp?.gatsbyImageData && (
                <GatsbyImage
                  image={lab_icon?.childImageSharp?.gatsbyImageData}
                  alt={lab_icon_alt || ''}
                />
              )}
              <h3
                dangerouslySetInnerHTML={{
                  __html: removeOuterTags(lab_title?.html) ?? '',
                }}
              />
              <div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: removeOuterTags(lab_text?.html) ?? '',
                  }}
                />
              </div>
            </Link>

            <Link
              onClick={handleScrollToForm}
              to={join(basepath, 'register-dealer')}
            >
              {dealer_icon?.childImageSharp?.gatsbyImageData && (
                <GatsbyImage
                  image={dealer_icon?.childImageSharp?.gatsbyImageData}
                  alt={dealer_icon_alt || ''}
                />
              )}
              <h3
                dangerouslySetInnerHTML={{
                  __html: removeOuterTags(dealer_title?.html) ?? '',
                }}
              />
              <div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: removeOuterTags(dealer_text?.html) ?? '',
                  }}
                />
              </div>
            </Link>

            {!hide_login_icon && (
              <Link onClick={handleScrollToForm} to={basepath}>
                {login_icon?.childImageSharp?.gatsbyImageData && (
                  <GatsbyImage
                    image={login_icon?.childImageSharp?.gatsbyImageData}
                    alt={login_icon_alt || ''}
                  />
                )}
                <h3
                  dangerouslySetInnerHTML={{
                    __html: removeOuterTags(login_title?.html) ?? '',
                  }}
                />
                <div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: removeOuterTags(login_text?.html) ?? '',
                    }}
                  />
                </div>
              </Link>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceSlice;

import * as Dialog from '@radix-ui/react-dialog';
import classNames from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import 'intersection-observer';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';

import { useIsArCapable, usePrefersReducedMotion } from '../../hooks/dom';
import { BannerSlice as BannerSliceProps } from '../../types/graphql';
import { removeOuterTags } from '../../util/html';
import { getHref } from '../../util/link';
import MakeLazy from '../../util/make-lazy';
import Button from '../button';
import Scroller from '../scroller';

import { SliceProps } from './sliceprops';

import '../../styles/slices/banner.scss';
import '../../styles/slices/playwright.scss';

export interface NativeBannerSliceProps {
  variant?: 'full-height' | 'normal-dark';
  video?: {
    mp4: string;
    ogg: string;
    webm: string;
  };
  title?: string;
  text?: string;
  buttons?: [
    {
      title: string;
      link: string;
    },
  ];
}

/**
 * The 3D model viewer component.
 *
 * Has quite the dependency tree, so we import it lazily to load when the modal is
 * shown.
 */
const ModelViewer = MakeLazy(
  () => import(/* webpackPrefetch: true */ './model-viewer'),
);

/** The default size of either the OPE or 3D-viewer modal. */
const DEFAULT_MODAL_SIZE = '1018/760';

const youtubeIdFromLink = (url: string) =>
  url.match(
    /(?:https?:\/\/)?(?:www\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/)([^\s&]+)/,
  )?.[1];

export const BannerSlice: React.FC<SliceProps<BannerSliceProps>> = ({
  data: {
    ar,
    banner_image,
    banner_image_alt,
    body,
    links,
    ope,
    scroll_down_text,
    slice_label,
    title,
    video_popup,
    video,
  },
  lang,
}) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isPopupOpened, setIsPopupOpened] = useState(false);

  const isArCapable = useIsArCapable();
  const prefersReducedMotion = usePrefersReducedMotion();

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (!videoElement) {
      return;
    }

    const startVideo: IntersectionObserverCallback = (entries) => {
      // If user doesn't like motion, don't start the video
      if (entries[0].isIntersecting && !prefersReducedMotion && videoElement) {
        videoElement
          .play()
          .catch((err) =>
            console.warn(`Could not start playing banner video: ${err}`),
          );
        observer.unobserve(videoElement);
      }
    };

    const observer = new IntersectionObserver(startVideo, { threshold: 0.5 });
    observer.observe(videoElement);

    const fadeVideoIn = () => {
      videoElement.removeEventListener('playing', fadeVideoIn);
      setIsVideoLoaded(true);
    };
    videoElement.addEventListener('playing', fadeVideoIn);

    return () => {
      videoElement.removeEventListener('playing', fadeVideoIn);
      observer.unobserve(videoElement);
    };
  }, [prefersReducedMotion]);

  const [modalSize, setModalSize] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const [width, height] = (ope?.ope_aspect_ratio ?? DEFAULT_MODAL_SIZE)
      .split('/')
      .map(Number);

    const handle = () => {
      if (window.innerWidth / window.innerHeight > width / height) {
        const elHeight = Math.min(window.innerHeight * 0.9, height);
        setModalSize({
          width: Math.floor((elHeight * width) / height),
          height: Math.ceil(elHeight),
        });
      } else {
        const elWidth = Math.min(window.innerWidth * 0.9, width);
        setModalSize({
          width: Math.floor(elWidth),
          height: Math.ceil((elWidth * height) / width),
        });
      }
    };

    handle();
    window.addEventListener('resize', handle);
    return () => window.removeEventListener('resize', handle);
  }, [ope?.ope_aspect_ratio]);

  const buttons = (links || []).filter(
    (item) =>
      item &&
      (item.rich_label?.html || item.label) &&
      (item.link || item.file?.publicURL || item.image?.publicURL || item.page_rel),
  );

  const showAugmentedReality = Boolean(isArCapable && ar?.ar_model_usdz?.publicURL);
  const show3dModel = Boolean(
    !showAugmentedReality && !isArCapable && ar?.ar_model_glb?.publicURL,
  );
  const showOpe = Boolean(!showAugmentedReality && ope?.ope_link);
  const showImage = Boolean(banner_image?.childImageSharp);

  const greenButtons = showAugmentedReality || show3dModel || showOpe;

  /*
   * Show video only if user likes motion content and all required source files are
   * available.
   *
   * Hide video if user doesn't like motion and we have an alternative image source
   * available.
   */
  const showVideo = prefersReducedMotion
    ? !showImage
    : video?.banner_video_mp4?.publicURL && video?.banner_video_webm?.publicURL;

  const showVideoPopupButton =
    !showAugmentedReality &&
    video_popup?.video_popup_link &&
    video_popup?.video_popup_title;

  const showText = Boolean(body?.html && body?.html !== '<p></p>');

  const showTitle = title?.html;

  const hasButtons = Boolean(
    buttons.length > 0 || showOpe || showAugmentedReality || show3dModel,
  );

  const isFullHeight =
    slice_label === 'full-height' || slice_label === 'full-height-dark';

  const popupVideoId = showVideoPopupButton
    ? youtubeIdFromLink(video_popup.video_popup_link)
    : null;

  return (
    <>
      {(showImage || showVideo) && <div className="background" />}

      {showImage && !showVideo && (
        <GatsbyImage
          className="image"
          image={banner_image?.childImageSharp?.gatsbyImageData}
          alt={banner_image_alt || ''}
          imgStyle={{ objectFit: 'cover' }}
        />
      )}

      {showVideo && (
        <video
          muted={true}
          loop={true}
          playsInline={true}
          ref={videoRef}
          className={classNames({ loaded: isVideoLoaded })}
        >
          {video?.banner_video_webm?.publicURL && (
            <source src={video.banner_video_webm.publicURL} type="video/webm" />
          )}
          {video?.banner_video_mp4?.publicURL && (
            <source src={video.banner_video_mp4.publicURL} type="video/mp4" />
          )}
          {video?.banner_video_ogg?.publicURL && (
            <source src={video.banner_video_ogg.publicURL} type="video/ogg" />
          )}
        </video>
      )}

      {(showImage || showVideo) && (showText || showTitle) && (
        <div className={classNames('overlay', showAugmentedReality && 'ar')} />
      )}

      <div
        className={classNames('content', {
          ar: showAugmentedReality,
          dark: showImage || showVideo,
          text: showText,
        })}
      >
        {title?.html && <h2>{removeOuterTags(title?.html)}</h2>}

        {showVideoPopupButton && (
          <>
            <a
              className="cta-icon video-play"
              onClick={() => setIsPopupOpened(true)}
              title={video_popup.video_popup_title}
            />
            {typeof window !== 'undefined' && (
              <ModalVideo
                channel="youtube"
                videoId={popupVideoId}
                isOpen={isPopupOpened}
                onClose={() => setIsPopupOpened(false)}
              />
            )}
          </>
        )}

        {showText && (
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: body!.html ?? '',
            }}
          />
        )}

        {hasButtons && (
          <div className="buttons">
            {showAugmentedReality && (
              <Button
                className={classNames({ green: greenButtons })}
                dark={!showImage && !showVideo ? 'dark' : undefined}
                href={ar!.ar_model_usdz.publicURL!}
                rel="ar"
              >
                <img className="ar-trigger" src="/images/transparent-8x8.png" />
                <span>{ar?.ar_cta_text ?? 'View in 3D with AR'}</span>
              </Button>
            )}
            {show3dModel && (
              <>
                <Helmet>
                  <link
                    crossOrigin="anonymous"
                    href={ar!.ar_model_glb.publicURL!}
                    rel="prefetch"
                  />
                </Helmet>

                <Dialog.Root>
                  <Dialog.Trigger asChild>
                    <Button
                      className={classNames({ green: greenButtons })}
                      dark={!showImage && !showVideo ? 'dark' : undefined}
                    >
                      {ar?.ar_cta_text ?? 'View in 3D'}
                    </Button>
                  </Dialog.Trigger>

                  <Dialog.Portal>
                    <Dialog.Overlay className="banner-dialog-overlay" />
                    <Dialog.Content
                      className="banner-dialog-content glassy"
                      style={modalSize}
                    >
                      <ModelViewer
                        camera-controls
                        exposure={1}
                        shadow-intensity={1}
                        src={ar!.ar_model_glb.publicURL!}
                      />
                    </Dialog.Content>
                  </Dialog.Portal>
                </Dialog.Root>
              </>
            )}
            {showOpe && (
              <Dialog.Root>
                <Dialog.Trigger asChild>
                  <Button
                    className={classNames({ green: greenButtons })}
                    dark={!showImage && !showVideo ? 'dark' : undefined}
                  >
                    {ope?.ope_cta_text}
                  </Button>
                </Dialog.Trigger>

                <Dialog.Portal>
                  <Dialog.Overlay className="banner-dialog-overlay" />
                  <Dialog.Content
                    className="banner-dialog-content"
                    style={modalSize}
                  >
                    <iframe
                      src={`https://www.figma.com/embed?embed_host=share&url=${ope?.ope_link}&hide-ui=1&scaling=scale-down-width&disable-default-keyboard-nav=1`}
                    />
                  </Dialog.Content>
                </Dialog.Portal>
              </Dialog.Root>
            )}
            {buttons!.map((item, i) => (
              <Button
                key={i}
                href={getHref(item, lang) || '#'}
                className={classNames({ green: greenButtons })}
                dark={!showImage && !showVideo ? 'dark' : undefined}
                dangerouslySetInnerHTML={{
                  __html: removeOuterTags(item.rich_label!.html) ?? item.label ?? '',
                }}
              />
            ))}
          </div>
        )}
      </div>

      {(scroll_down_text || isFullHeight) && (
        <Scroller text={scroll_down_text || undefined} />
      )}
    </>
  );
};

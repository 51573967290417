import React from 'react';

import { FeatureListSlice as FeatureListSliceProps } from '../../types/graphql';
import { removeOuterTags } from '../../util/html';

import { SliceProps } from './sliceprops';

import '../../styles/slices/feature-list.scss';

export const FeatureListSlice: React.FC<SliceProps<FeatureListSliceProps>> = ({
  data: { feature_list_entries, title },
}) => (
  <div className="container">
    <h2 dangerouslySetInnerHTML={{ __html: removeOuterTags(title?.html) ?? '' }} />

    <hr />

    <div className="feature-grid">
      {feature_list_entries.map((item, i) => (
        <div key={i} className="feature">
          <h3
            dangerouslySetInnerHTML={{
              __html: removeOuterTags(item.title?.html) ?? '',
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: item?.body?.html ?? '',
            }}
          />
        </div>
      ))}
    </div>
    <hr />
  </div>
);

import { graphql } from 'gatsby';
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import invariant from 'tiny-invariant';

import linkResolver from '../../link-resolver';
import { PageDataQuery } from '../types/graphql';

import '../styles/components/cookie-consent.scss';

declare global {
  interface Window {
    cookieconsent: any;
  }
}

interface CookieConsentProps {
  content: PageDataQuery['cookieConsent'];
  lang: string;
}

export const CookieConsent: React.FC<CookieConsentProps> = ({ content, lang }) => {
  const script = useRef<HTMLScriptElement | null>(null);
  const popup = useRef<any | null>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      invariant(
        content?.frontmatter?.__typename === 'CookieConsentFrontmatter',
        'need a cookie_consent',
      );

      const data = content?.frontmatter;

      if (!data) {
        return;
      }

      const init = () => {
        if (!window.cookieconsent) {
          setTimeout(init, 1000);
          return;
        }

        window.cookieconsent.initialise(
          {
            palette: {
              popup: {
                background: 'rgba(0,0,0,0.7)',
              },
              button: {
                background: '#931f49',
              },
            },
            position: 'bottom-left',
            type: 'opt-in',
            content: {
              message: data.message,
              allow: data.accept_button_text,
              deny: data.deny_button_text,
              link: data.policy_link_text,
              href:
                data.link?.link ||
                data.link?.file?.publicURL ||
                linkResolver({ uid: data.link.page_rel, lang }),
              policy: data.cookie_policy_text,
            },
          },
          (p) => (popup.current = p),
        );
      };

      if (window.cookieconsent) {
        init();
      } else if (script.current) {
        script.current.addEventListener('load', () => setTimeout(init, 1000));
      } else {
        setTimeout(init, 1000);
      }
    }, 250);

    return () => {
      clearTimeout(timeout);
      popup.current?.destroy();
    };
  }, []);

  return (
    <Helmet>
      <link
        rel="stylesheet"
        type="text/css"
        href="//cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.1.1/cookieconsent.min.css"
      />
      <script
        src="//cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.1.1/cookieconsent.min.js"
        ref={script}
      />
    </Helmet>
  );
};

export const query = graphql`
  fragment CookieConsent on MarkdownRemark {
    frontmatter {
      ... on CookieConsentFrontmatter {
        __typename
        accept_button_text
        cookie_policy_text
        deny_button_text
        link {
          file {
            publicURL
          }
          link
          page_rel
        }
        message
        policy_link_text
        title
      }
    }
    fields {
      lang
    }
  }
`;

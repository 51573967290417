import classNames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useEffect, useCallback, useState, useRef } from 'react';

import { useCountryName } from '../../hooks/countries';
import {
  PartnerListSlice as PartnerListSliceProps,
  PartnerListQuery,
} from '../../types/graphql';
import animatedScroll from '../../util/animated-scroll';

import chevronWhite from '../../../static/images/chevron-down-white.svg';
import mailIcon from '../../../static/images/envelope-o.svg';
import faxIcon from '../../../static/images/fax.svg';
import globeIcon from '../../../static/images/globe.svg';
import phoneIcon from '../../../static/images/phone.svg';
import '../../styles/slices/partner-list.scss';

import { SliceProps } from './sliceprops';

const SALES = 'sales';
const SERVICE = 'service';
type PartnerType = typeof SALES | typeof SERVICE | null;
const parsePartnerType = (str?: string): PartnerType => {
  switch (str) {
    case SALES:
      return SALES;
    case SERVICE:
      return SERVICE;
    default:
      return null;
  }
};

const partnerListQuery = graphql`
  query PartnerList {
    allPartnersJson {
      nodes {
        id
        name
        address
        countries
        types
        phone
        fax
        mail
        website
        lat
        lng
      }
    }
  }
`;

export const PartnerListSlice: React.FC<SliceProps<PartnerListSliceProps>> = ({
  data: {
    choose_country_text,
    our_partners_text,
    sales_partner_icon,
    sales_partner_icon_alt,
    sales_partner_text,
    service_partner_icon_alt,
    service_partner_icon,
    service_partner_text,
  },
  lang,
}) => {
  const { getLocalizedCountryName } = useCountryName(lang);

  const countryChooserRef = useRef<HTMLDivElement>(null);
  const companyListRef = useRef<HTMLDivElement>(null);

  const [selectedType, setSelectedType] = useState<PartnerType>(null);
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const partnerList: PartnerListQuery = useStaticQuery(partnerListQuery);

  useEffect(() => {
    const [typeData, countryData] = window.location.hash
      .substring(1)
      .toLowerCase()
      .split('/');
    const type = parsePartnerType(typeData);
    if (!type) {
      return;
    }
    const languages = getLanguages(partnerList.allPartnersJson.nodes, type);
    const country =
      languages.find(
        (lang) =>
          lang.toLowerCase().replace(/\s/gi, '') ===
          countryData?.replace(/\s/gi, ''),
      ) || null;
    setSelectedType(type);
    setSelectedCountry(country);
    // ensure scroll after rendering new state https://stackoverflow.com/a/34999925
    window.setTimeout(() =>
      window.requestAnimationFrame(() => {
        if (country) {
          animatedScroll(companyListRef.current!);
        } else {
          animatedScroll(countryChooserRef.current!);
        }
      }),
    );
  }, []);

  const getLanguages = (
    items: PartnerListQuery['allPartnersJson']['nodes'],
    type: string | null,
  ) => {
    const filteredItems = type
      ? items.filter((i) => i && i.types?.includes(type))
      : items;

    return filteredItems
      .reduce((acc, cur) => {
        if (!cur) {
          return acc;
        }

        const newItems = cur.countries?.filter((c) => c && !acc.includes(c)) ?? [];
        return acc.concat(newItems as string[]);
      }, [] as string[])
      .sort((a_, b_) => {
        const a = getLocalizedCountryName(a_);
        const b = getLocalizedCountryName(b_);
        return a.localeCompare(b, lang);
      });
  };

  const getFilteredCompanies = useCallback(
    (items: PartnerListQuery['allPartnersJson']['nodes']) => {
      const filtered = items
        .filter(
          (i) =>
            i &&
            i.types?.includes(selectedType) &&
            i.countries?.includes(selectedCountry),
        )
        .sort((a, b) => {
          if (a.name?.toLowerCase().startsWith('dekema')) {
            return -1;
          }

          if (b.name?.toLowerCase().startsWith('dekema')) {
            return 1;
          }

          return a.name!.localeCompare(b.name!);
        });

      if (selectedType && selectedCountry) {
        document.dispatchEvent(
          new CustomEvent('partner-filter', {
            detail: { ids: filtered.map((i) => i!.id) },
          }),
        );
      }

      return filtered;
    },
    [selectedCountry, selectedType],
  );

  const onTypeSalesClick = useCallback(
    (ev: React.MouseEvent<HTMLAnchorElement>) => {
      ev.preventDefault();
      window.location.hash = SALES;
      animatedScroll(countryChooserRef.current!);
      setSelectedCountry(null);
      setSelectedType(SALES);
    },
    [countryChooserRef.current],
  );

  const onTypeServiceClick = useCallback(
    (ev: React.MouseEvent<HTMLAnchorElement>) => {
      ev.preventDefault();
      window.location.hash = SERVICE;
      animatedScroll(countryChooserRef.current!);
      setSelectedCountry(null);
      setSelectedType(SERVICE);
    },
    [countryChooserRef.current],
  );

  const onCountryClick = useCallback(
    (ev: React.MouseEvent<HTMLAnchorElement>, country: string) => {
      ev.preventDefault();
      window.location.hash = `${selectedType}/${country
        .toLowerCase()
        .replace(/\s/gi, '')}`;
      animatedScroll(companyListRef.current!);
      setSelectedCountry(country);
    },
    [companyListRef.current, selectedType],
  );

  return (
    <div className="container">
      <div className="icons">
        <a
          href={`#${SALES}`}
          className={classNames({
            active: selectedType === SALES,
          })}
          onClick={onTypeSalesClick}
        >
          <GatsbyImage
            alt={sales_partner_icon_alt ?? ''}
            image={sales_partner_icon?.childImageSharp?.gatsbyImageData}
          />
          <h3>{sales_partner_text}</h3>
        </a>
        <a
          href={`#${SERVICE}`}
          className={classNames({
            active: selectedType == SERVICE,
          })}
          onClick={onTypeServiceClick}
        >
          <GatsbyImage
            alt={service_partner_icon_alt || ''}
            image={service_partner_icon?.childImageSharp?.gatsbyImageData}
          />
          <h3>{service_partner_text}</h3>
        </a>
      </div>

      <div
        ref={countryChooserRef}
        className={classNames('toggler country-chooser', {
          active: selectedType,
        })}
      >
        <div className="heading">
          <h2>{choose_country_text}</h2>
          <img src={chevronWhite} aria-hidden="true" />
        </div>

        <ul>
          {getLanguages(partnerList.allPartnersJson.nodes, selectedType).map(
            (country) => (
              <li key={country}>
                <a
                  href={`#${selectedType}/${country
                    .replace(/\s/gi, '')
                    .toLowerCase()}`}
                  className={classNames({
                    active: selectedCountry == country,
                  })}
                  onClick={(ev) => onCountryClick(ev, country)}
                >
                  {getLocalizedCountryName(country)}
                </a>
              </li>
            ),
          )}
        </ul>
      </div>

      <div
        ref={companyListRef}
        className={classNames('toggler company-list', {
          active: selectedType && selectedCountry,
        })}
      >
        <div className="heading">
          <h2>{our_partners_text}</h2>
          <img src={chevronWhite} aria-hidden="true" />
        </div>

        <ul>
          {getFilteredCompanies(partnerList.allPartnersJson.nodes).map(
            (company, i) => {
              const onClick = () => {
                document.dispatchEvent(
                  new CustomEvent('partner-map-show', {
                    detail: { id: company!.id },
                  }),
                );
              };
              return (
                <li key={i}>
                  <h3 onClick={onClick}>{company!.name}</h3>
                  <address onClick={onClick}>{company!.address}</address>

                  <ul className="contact-data">
                    {company!.phone && (
                      <li>
                        <img src={phoneIcon} aria-hidden="true" />
                        <a href={`tel:${company!.phone}`} aria-label="Phone">
                          {company!.phone}
                        </a>
                      </li>
                    )}
                    {company!.fax && (
                      <li>
                        <img src={faxIcon} aria-hidden="true" />
                        <a href={`fax:${company!.fax}`} aria-label="Fax">
                          {company!.fax}
                        </a>
                      </li>
                    )}
                    {company!.mail && (
                      <li>
                        <img src={mailIcon} aria-hidden="true" />
                        <a aria-label="Mail">
                          {company!.mail?.replace('@', '[at]')}
                        </a>
                      </li>
                    )}
                    {company!.website && (
                      <li>
                        <img src={globeIcon} aria-hidden="true" />
                        <a href={company!.website!} aria-label="Website">
                          {company!.website}
                        </a>
                      </li>
                    )}
                  </ul>
                </li>
              );
            },
          )}
        </ul>
      </div>
    </div>
  );
};

import * as Sentry from '@sentry/browser';
import React from 'react';

import errorIcon from '../../static/images/alert-circle-outline.svg';
import '../styles/components/fatal-error.scss';
import Button from './button';

interface FatalErrorProps {
  sentryOptions?: Sentry.ReportDialogOptions;
}

const FatalError: React.FC<FatalErrorProps> = ({ sentryOptions }) => (
  <div className="fatal-error">
    <div className="content">
      <img src={errorIcon} alt="Error Icon" />
      <p>
        We've encountered a fatal error. Please reload the page to continue. If you'd
        like to help us fix this issue, please submit an error report.
      </p>
      <Button onClick={() => Sentry.showReportDialog(sentryOptions)}>
        Submit an Error Report
      </Button>
    </div>
  </div>
);

export default FatalError;

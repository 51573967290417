import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { ImageSlice as ImageSliceProps } from '../../types/graphql';

import { SliceProps } from './sliceprops';

import '../../styles/slices/image.scss';

export const ImageSlice: React.FC<SliceProps<ImageSliceProps>> = ({
  data: { image, image_alt, max_width },
}) => (
  <div
    className="img"
    style={{
      maxWidth: max_width ? Number(max_width) : undefined,
    }}
  >
    <GatsbyImage
      className="image"
      image={image?.childImageSharp?.gatsbyImageData}
      alt={image_alt || ''}
    />
  </div>
);

export const range = (start: number, stop = -1, step = 1): number[] => {
  if (stop === -1) {
    stop = start;
    start = 0;
  }

  return Array(Math.ceil((stop - start) / step))
    .fill(start)
    .map((x, y) => x + y * step);
};

import React, { useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { submitPublicContactFormRequest } from '../../model/contact';
import { ContactSlice as ContactSliceProps } from '../../types/graphql';
import animatedScroll from '../../util/animated-scroll';
import { removeOuterTags } from '../../util/html';

import { SliceProps } from './sliceprops';

import '../../styles/components/button.scss';
import '../../styles/components/forms.scss';
import '../../styles/slices/contact-form.scss';

const getFormInput = (
  type: string,
  label: string,
  required: boolean,
  items?: string,
  handleInputChange?,
) => {
  const fieldProps = {
    name: label,
    required,
    onChange: handleInputChange,
  };

  if (type === 'Area') {
    return <textarea {...fieldProps} />;
  }

  if (type === 'Select' && items) {
    return (
      <select {...fieldProps}>
        <option>---</option>
        {items.split('\n').map((item) => (
          <option key={item} value={item}>
            {item}
          </option>
        ))}
      </select>
    );
  }

  return <input {...fieldProps} type={type} />;
};

export const ContactFormSlice: React.FC<SliceProps<ContactSliceProps>> = ({
  data,
}) => {
  const contactForm = React.createRef<HTMLDivElement>();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [formData, setFormData] = useState<Record<string, string | boolean>>({});
  const [state, setState] = useState<'idle' | 'sending' | 'sent' | 'failed'>('idle');
  const [productName, setProductName] = useState<string>();

  useEffect(() => {
    if (window.location.hash.length > 1) {
      const productName = decodeURIComponent(window.location.hash.substr(1));

      if (contactForm.current) {
        animatedScroll(contactForm.current);
      }

      setProductName(productName);
      setFormData((d) => ({ ...d, 'Product Name': productName }));
    }
  }, []);

  const handleInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const target = ev.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setFormData((d) => ({ ...d, [name]: value }));
  };

  const handleSubmit = async (ev: React.MouseEvent<HTMLFormElement>) => {
    ev.preventDefault();
    setState('sending');

    const recaptchaToken = await executeRecaptcha?.('submitForm');
    if (!recaptchaToken) {
      return;
    }

    try {
      await submitPublicContactFormRequest(recaptchaToken, formData);
      setState('sent');
    } catch (e) {
      setState('failed');
    }
  };

  const { error_text, fields, please_wait_text, submit_text, success_text, title } =
    data;

  return (
    <div className="form-container" ref={contactForm}>
      <h2 dangerouslySetInnerHTML={{ __html: removeOuterTags(title?.html) ?? '' }} />

      {state === 'sent' ? (
        <p>{success_text}</p>
      ) : state === 'failed' ? (
        <p>{error_text}</p>
      ) : (
        <form action="#" onSubmit={handleSubmit}>
          {productName && <h3 className="highlight">{productName}</h3>}
          {fields?.map((item) => (
            <div className="form-item" key={item!.label!}>
              <label>
                <span>
                  {item!.label}
                  {item!.field_required && ' *'}
                </span>

                {getFormInput(
                  item!.field_type!,
                  item!.label!,
                  Boolean(item!.field_required),
                  item!.options!,
                  handleInputChange,
                )}
              </label>
            </div>
          ))}
          <button className="btn dark slim" type="submit">
            {state === 'sending' ? please_wait_text : submit_text}
          </button>
        </form>
      )}
    </div>
  );
};

import classNames from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useEffect, useRef, useState } from 'react';
import slugify from 'slugify';
import invariant from 'tiny-invariant';

import { useAllProduct } from '../../hooks/cms-products';
import { ProductListSlice as ProductListSliceProps } from '../../types/graphql';
import animatedScroll from '../../util/animated-scroll';
import { removeOuterTags } from '../../util/html';
import { getHref } from '../../util/link';
import Button from '../button';

import { SliceProps } from './sliceprops';

import chevronDownWhite from '../../../static/images/chevron-down-white.svg';
import chevronDownBlack from '../../../static/images/chevron-down.svg';
import '../../styles/slices/product-list.scss';

export const ProductListSlice: React.FC<SliceProps<ProductListSliceProps>> = ({
  data: {
    anchor_id,
    contact_link,
    contact_text,
    product_links,
    show_products_text,
    slice_label,
    title,
  },
  lang,
}) => {
  const allProducts = useAllProduct();
  const productsToShow = product_links
    ?.map((prod) =>
      allProducts.find((prodMeta) => prodMeta.fields.uid === prod?.product),
    )
    .filter((meta) => meta);

  const [isOpen, setIsOpen] = useState(false);

  const productListRef = useRef<HTMLDivElement>(null);
  const productItemRefs = useRef<Record<string, HTMLDivElement>>({});

  const scrollToElementWithSlug = (slug: string) => {
    const el = productItemRefs.current[slug];
    if (!el) {
      return;
    }

    animatedScroll(el);

    window.history.replaceState('', '', `#${slug}`);
    setIsOpen(true);
  };

  const handleShowMoreClick = (ev: React.MouseEvent) => {
    ev.preventDefault();

    if (productListRef.current) {
      animatedScroll(productListRef.current);
    }

    setIsOpen(true);
  };

  useEffect(() => {
    if (window.location.hash.length > 1) {
      scrollToElementWithSlug(window.location.hash.slice(1));
    }
  }, []);

  return (
    <div id={anchor_id || undefined}>
      <div className="container">
        <h2
          dangerouslySetInnerHTML={{
            __html: removeOuterTags(title?.html) ?? '',
          }}
        />
        {productsToShow && productsToShow.length > 1 && (
          <>
            <div className="product-grid">
              {productsToShow.map((meta, i) => {
                invariant(meta?.frontmatter?.__typename === 'ProductFrontmatter');

                const slug = meta?.frontmatter.name?.excerpt
                  ? slugify(meta.frontmatter.name.excerpt)
                  : meta.fields.uid;

                const handleProductClick = (ev: React.MouseEvent) => {
                  ev.preventDefault();
                  scrollToElementWithSlug(slug);
                };

                return (
                  <a
                    key={i}
                    className="product"
                    href={`#${slug}`}
                    onClick={handleProductClick}
                  >
                    <GatsbyImage
                      image={
                        meta?.frontmatter.image.childImageSharp?.gatsbyImageData
                      }
                      alt={meta.frontmatter.image_alt ?? ''}
                      className="image"
                    />
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: removeOuterTags(meta.frontmatter.name?.html) ?? '',
                      }}
                    />
                  </a>
                );
              })}
            </div>

            <Button
              className="more"
              href="#"
              dark={slice_label === 'bright' ? 'dark' : undefined}
              onClick={handleShowMoreClick}
            >
              {show_products_text}
              <img
                src={slice_label === 'bright' ? chevronDownBlack : chevronDownWhite}
                alt="Chevron Down"
              />
            </Button>
          </>
        )}
      </div>

      <div
        className={classNames('product-list', {
          open: isOpen || !productsToShow || productsToShow.length < 2,
        })}
        ref={productListRef}
      >
        {productsToShow!.map((meta) => {
          invariant(meta?.frontmatter?.__typename === 'ProductFrontmatter');

          const slug = meta?.frontmatter.name?.excerpt
            ? slugify(meta.frontmatter.name.excerpt)
            : meta.fields.uid;

          return (
            <div
              key={meta?.fields.uid}
              className="product"
              id={slug}
              ref={(r) => r && (productItemRefs.current[slug] = r)}
            >
              <GatsbyImage
                image={meta.frontmatter.image.childImageSharp?.gatsbyImageData}
                alt={meta.frontmatter.image_alt ?? ''}
                className="image"
              />
              <div className="description">
                <h3
                  dangerouslySetInnerHTML={{
                    __html: removeOuterTags(meta.frontmatter.name?.html) ?? '',
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: removeOuterTags(meta.html) ?? '',
                  }}
                />

                <Button
                  href={`${getHref(contact_link, lang)}#${slug}`}
                  dark={slice_label === 'bright' ? 'dark' : undefined}
                >
                  {contact_text}
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import 'intersection-observer';

import { Austromat220Slice as Austromat220SliceProps } from '../../types/graphql';
import { removeOuterTags } from '../../util/html';
import { SliceProps } from './sliceprops';

import '../../styles/slices/austromat-220.scss';

export type Austromat220Props = SliceProps<Austromat220SliceProps>;

export const Austromat220Slice: React.FC<Austromat220Props> = ({
  data: { austromat_220_entries: entries },
}) => {
  const sectionsRef = useRef<HTMLDivElement>(null);
  const [activeImage, setActiveImage] = useState(0);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((e) => {
          if (e.isIntersecting) {
            const image = Number((e.target as HTMLDivElement).dataset.image);
            setActiveImage(image);
          }
        });
      },
      { threshold: 0.7 },
    );

    sectionsRef.current?.querySelectorAll('section').forEach((e) => {
      observer.observe(e);
    });

    return () => observer.disconnect();
  }, [sectionsRef.current]);

  return (
    <div className="container">
      <div className="sections" ref={sectionsRef}>
        {entries?.map((item, i) => (
          <section key={i} data-image={i}>
            <h2
              dangerouslySetInnerHTML={{
                __html: removeOuterTags(item?.title?.html) ?? '',
              }}
            />
            <div dangerouslySetInnerHTML={{ __html: item?.body?.html ?? '' }} />
          </section>
        ))}
      </div>
      <div className="images">
        {entries?.map((item, i) => (
          /* GatsbyImage didn't play nice with the transitions on state changes */
          <img
            key={item?.image?.publicURL}
            loading="eager"
            className={classNames('image', { visible: activeImage >= i })}
            srcSet={
              item?.image?.childImageSharp?.gatsbyImageData.images.fallback.srcSet
            }
            alt={item?.image_alt || ''}
          />
        ))}
      </div>
    </div>
  );
};

/**
 * A small helper file that adapts the react-google-maps typings to @types/react v18
 */

import React from 'react';
import {
  GoogleMap as GoogleMap_,
  GoogleMapProps,
  InfoWindow as InfoWindow_,
  InfoWindowProps,
  Marker as Marker_,
  MarkerProps,
} from 'react-google-maps';

export const GoogleMap = GoogleMap_ as React.ComponentClass<
  React.PropsWithChildren<GoogleMapProps>
>;
export const InfoWindow = InfoWindow_ as React.ComponentClass<
  React.PropsWithChildren<InfoWindowProps>
>;
export const Marker = Marker_ as React.ComponentClass<
  React.PropsWithChildren<MarkerProps>
>;

import { graphql, StaticQuery } from 'gatsby';
import isEqual from 'lodash/isEqual';
import React from 'react';
import {
  withGoogleMap,
  WithGoogleMapProps,
  withScriptjs,
  WithScriptjsProps,
} from 'react-google-maps';

import { PartnerMapEntriesQuery, PartnersJson } from '../../types/graphql';
import animatedScroll from '../../util/animated-scroll';
import { mapOptions } from '../../util/map';
import { GoogleMap, InfoWindow, Marker } from '../../util/react-google-maps';

import faxIcon from '../../../static/images/fax-dark.svg';
import globeIcon from '../../../static/images/globe-dark.svg';
import mailIcon from '../../../static/images/envelope-o-dark.svg';
import phoneIcon from '../../../static/images/phone-dark.svg';
import pin from '../../../static/images/map-pin.svg';
import '../../styles/slices/partner-map.scss';

import { SliceProps } from './sliceprops';

interface MapProps {
  companies: readonly Partner[];
  openInfoBoxId: string | null;
  openInfoWindow: (id: string) => void;
  handleInfoWindowClose: () => void;
  filter: string[] | null;
}

type Partner = Pick<
  PartnersJson,
  'address' | 'fax' | 'id' | 'mail' | 'name' | 'lat' | 'lng' | 'phone' | 'website'
>;

class Map extends React.Component<MapProps, any> {
  mapRef = React.createRef<typeof GoogleMap>();

  calculateBounds = (partners: Partner[]) => {
    const bounds = new google.maps.LatLngBounds();
    partners
      .filter((p) => p.lat && p.lng)
      .forEach((partner) => bounds.extend({ lat: partner.lat!, lng: partner.lng! }));
    return bounds;
  };

  componentDidMount() {
    this.fitBounds(this.props.filter);
  }

  shouldComponentUpdate(nextProps: MapProps) {
    if (!isEqual(nextProps.filter, this.props.filter)) {
      this.fitBounds(nextProps.filter);
    }

    return true;
  }

  fitBounds(filter?: string[] | null) {
    const companies = this.props.companies.filter(
      (e) => !filter || filter.includes(e!.id!),
    );
    (this.mapRef.current as any)?.fitBounds(this.calculateBounds(companies));
  }

  render() {
    return (
      <GoogleMap options={mapOptions} ref={this.mapRef as any}>
        {this.props.companies &&
          this.props.companies
            .filter((e) => e.lat && e.lng)
            .map((item) => (
              <Marker
                key={item.id}
                position={{
                  lat: item.lat!,
                  lng: item.lng!,
                }}
                icon={pin}
                onClick={this.props.openInfoWindow.bind(null, item!.id)}
              >
                {this.props.openInfoBoxId === item!.id && (
                  <InfoWindow onCloseClick={this.props.handleInfoWindowClose}>
                    <div className="info-window">
                      <h3>{item!.name}</h3>
                      <address>{item!.address}</address>
                      <ul>
                        {item?.phone && (
                          <li>
                            <img src={phoneIcon} aria-hidden="true" />
                            <a href={`tel:${item.phone}`} aria-label="Phone">
                              {item.phone}
                            </a>
                          </li>
                        )}
                        {item?.fax && (
                          <li>
                            <img src={faxIcon} aria-hidden="true" />
                            <a href={`fax:${item.fax}`} aria-label="Fax">
                              {item.fax}
                            </a>
                          </li>
                        )}
                        {item?.mail && (
                          <li>
                            <img src={mailIcon} aria-hidden="true" />
                            <a aria-label="Mail" href={`mailto:${item!.mail}`}>
                              {item!.mail}
                            </a>
                          </li>
                        )}
                        {item?.website && (
                          <li>
                            <img src={globeIcon} aria-hidden="true" />
                            <a href={item!.website} rel="noreferrer" target="_blank">
                              {item!.website}
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            ))}
      </GoogleMap>
    );
  }
}

const InfoMap = withScriptjs(
  withGoogleMap((props: any) => <Map {...props} />),
) as React.ComponentClass<WithScriptjsProps & WithGoogleMapProps & MapProps>;

interface MapState {
  openInfoBoxId: string | null;
  filter: string[] | null;
}

export class PartnerMap extends React.Component<SliceProps<never>, MapState> {
  mapRef = React.createRef<typeof GoogleMap>();

  constructor(props) {
    super(props);

    this.state = {
      openInfoBoxId: null,
      filter: null,
    };
  }

  handleInfoWindowClose = () => {
    this.setState({
      openInfoBoxId: null,
    });
  };

  openInfoWindow = (id) => {
    this.setState({
      openInfoBoxId: id,
    });
  };

  componentDidMount() {
    document.addEventListener('partner-filter', ({ detail: { ids } }: any) => {
      this.setState({ filter: ids });
    });

    document.addEventListener('partner-map-show', ({ detail: { id } }: any) => {
      animatedScroll(0);
      this.setState({ openInfoBoxId: id });
    });
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query PartnerMapEntries {
            allPartnersJson {
              nodes {
                address
                fax
                id
                mail
                name
                lat
                lng
                phone
                website
              }
            }
          }
        `}
        render={(data: PartnerMapEntriesQuery) => (
          <InfoMap
            containerElement={<div style={{ height: `100%` }} />}
            loadingElement={<div />}
            mapElement={<div style={{ height: `100%` }} />}
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_MAPS_KEY}&v=3.exp&libraries=places&language=${this.props.lang}`}
            companies={data.allPartnersJson!.nodes}
            ref={this.mapRef as any}
            openInfoWindow={this.openInfoWindow}
            handleInfoWindowClose={this.handleInfoWindowClose}
            {...this.props}
            {...this.state}
          />
        )}
      />
    );
  }
}

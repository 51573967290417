import styles from '../styles/map-styles';

export const mapOptions: google.maps.MapOptions = {
  mapTypeId: 'roadmap' as any,
  zoom: 16,
  maxZoom: 18,
  minZoom: 1,
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: true,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  scrollwheel: false,
  styles,
};

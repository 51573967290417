import classNames from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useKeenSlider } from 'keen-slider/react';

import { usePrefersReducedMotion } from '../../hooks/dom';
import {
  CarouselPage as CarouselPageProps,
  CarouselSlice as CarouselSliceProps,
} from '../../types/graphql';
import { getHref } from '../../util/link';
import { removeOuterTags } from '../../util/html';
import { range } from '../../util/range';
import Button from '../button';

import { SliceProps } from './sliceprops';

import '../../styles/slices/carousel.scss';

interface CarouselSlicePageProps {
  lang: string;
  page: CarouselPageProps;
  prefersReducedMotion: boolean;
}

const CarouselPage: React.FC<CarouselSlicePageProps> = ({
  lang,
  page,
  prefersReducedMotion,
}) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (!videoElement) {
      return;
    }

    const startVideo: IntersectionObserverCallback = (entries) => {
      // If user doesn't like motion, don't start the video
      if (entries[0].isIntersecting && !prefersReducedMotion && videoElement) {
        videoElement
          .play()
          .catch((err) =>
            console.warn(`Could not start playing banner video: ${err}`),
          );
        observer.unobserve(videoElement);
      }
    };

    const observer = new IntersectionObserver(startVideo, { threshold: 0.5 });
    observer.observe(videoElement);

    const fadeVideoIn = () => {
      videoElement.removeEventListener('playing', fadeVideoIn);
      setIsVideoLoaded(true);
    };
    videoElement.addEventListener('playing', fadeVideoIn);

    return () => {
      videoElement.removeEventListener('playing', fadeVideoIn);
      observer.unobserve(videoElement);
    };
  }, [prefersReducedMotion]);

  const {
    carousel_image_w1,
    carousel_image_w2,
    carousel_image_w3,
    carousel_image_alt,
    carousel_page_links,
    page_title,
    subsubtitle,
    subtitle,
    video,
  } = page;

  const showImage = Boolean(
    carousel_image_w1?.childImageSharp ||
      carousel_image_w2?.childImageSharp ||
      carousel_image_w3?.childImageSharp,
  );
  const showVideo =
    !prefersReducedMotion &&
    video?.carousel_video_mp4.publicURL &&
    video.carousel_video_webm?.publicURL;

  const buttons = (carousel_page_links || []).filter(
    (item) =>
      item &&
      (item.rich_label?.html || item.label) &&
      (item.link || item.file?.publicURL || item.image?.publicURL || item.page_rel),
  );

  const hasContents =
    page_title?.html || subtitle?.html || buttons.length > 0 || subsubtitle?.html;

  return (
    <div className="page keen-slider__slide">
      {showImage && !showVideo && (
        <>
          {carousel_image_w1?.childImageSharp?.gatsbyImageData && (
            <GatsbyImage
              className={classNames('image', {
                w3:
                  carousel_image_w2?.childImageSharp?.gatsbyImageData ||
                  carousel_image_w3?.childImageSharp?.gatsbyImageData,
              })}
              image={carousel_image_w1.childImageSharp.gatsbyImageData}
              alt={carousel_image_alt || ''}
              imgStyle={{ objectFit: 'cover' }}
            />
          )}
          {carousel_image_w2?.childImageSharp?.gatsbyImageData && (
            <GatsbyImage
              className={classNames('image', {
                w2:
                  carousel_image_w1?.childImageSharp?.gatsbyImageData ||
                  carousel_image_w3?.childImageSharp?.gatsbyImageData,
              })}
              image={carousel_image_w2.childImageSharp.gatsbyImageData}
              alt={carousel_image_alt || ''}
              imgStyle={{ objectFit: 'cover' }}
            />
          )}
          {carousel_image_w3?.childImageSharp?.gatsbyImageData && (
            <GatsbyImage
              className={classNames('image', {
                w3:
                  carousel_image_w1?.childImageSharp?.gatsbyImageData ||
                  carousel_image_w2?.childImageSharp?.gatsbyImageData,
              })}
              image={carousel_image_w3.childImageSharp.gatsbyImageData}
              alt={carousel_image_alt || ''}
              imgStyle={{ objectFit: 'cover' }}
            />
          )}
        </>
      )}
      {showVideo && !showImage && (
        <video
          className={classNames({ loaded: isVideoLoaded })}
          loop={true}
          muted={true}
          playsInline={true}
          ref={videoRef}
        >
          {video?.carousel_video_webm?.publicURL && (
            <source src={video.carousel_video_webm.publicURL} type="video/webm" />
          )}
          {video?.carousel_video_mp4?.publicURL && (
            <source src={video.carousel_video_mp4.publicURL} type="video/mp4" />
          )}
          {video?.carousel_video_ogg?.publicURL && (
            <source src={video.carousel_video_ogg.publicURL} type="video/ogg" />
          )}
        </video>
      )}

      {hasContents && (
        <div className="page-contents-container">
          <div className="page-contents">
            {page_title?.html && (
              <h2
                dangerouslySetInnerHTML={{
                  __html: removeOuterTags(page_title.html),
                }}
              />
            )}
            {subtitle?.html && (
              <h3
                dangerouslySetInnerHTML={{
                  __html: removeOuterTags(subtitle.html),
                }}
              />
            )}
            {buttons?.length > 0 && (
              <div className="links">
                {buttons.map((item, i) => (
                  <Button
                    key={i}
                    href={getHref(item, lang) || '#'}
                    className={classNames({})}
                    dark={!showImage && !showVideo ? 'dark' : undefined}
                    dangerouslySetInnerHTML={{
                      __html:
                        removeOuterTags(item.rich_label!.html) ?? item.label ?? '',
                    }}
                  />
                ))}
              </div>
            )}
            {subsubtitle?.html && (
              <p
                dangerouslySetInnerHTML={{
                  __html: removeOuterTags(subsubtitle.html),
                }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export const CarouselSlice: React.FC<SliceProps<CarouselSliceProps>> = ({
  data: { auto_rotate, rotation_duration, pages },
  lang,
}) => {
  if (auto_rotate && rotation_duration <= 0) {
    throw new Error(`Carousel rotation duration must be > 0.`);
  }

  const prefersReducedMotion = usePrefersReducedMotion();

  const [activePageIndex, setActivePageIndex] = useState(0);
  const activePage = pages?.[activePageIndex];

  const containerRef = useRef<HTMLDivElement>();
  const [containerRefCallback_, sliderRef] = useKeenSlider({
    loop: true,
    slideChanged: (slider) =>
      pages &&
      slider.track.details &&
      setActivePageIndex(slider.track.details.abs % pages.length),
  });

  const containerRefCallback = useCallback(
    (node: HTMLDivElement) => {
      containerRef.current = node;
      containerRefCallback_(node);
    },
    [containerRefCallback_],
  );

  const [isAutoRotating, setIsAutoRotating] = useState(false);

  useEffect(() => {
    if (
      !auto_rotate ||
      prefersReducedMotion ||
      !pages ||
      pages.length <= 1 ||
      !sliderRef.current ||
      !containerRef.current
    ) {
      return;
    }

    const container = containerRef.current;

    let isMouseOver = false;
    let timeout;

    const nextTimeout = () => {
      clearTimeout(timeout);

      if (isMouseOver) {
        return;
      }

      setIsAutoRotating(true);
      timeout = setTimeout(() => {
        sliderRef.current?.next();

        // Reset the loading bars
        setIsAutoRotating(false);
        requestAnimationFrame(() =>
          requestAnimationFrame(() => setIsAutoRotating(true)),
        );
      }, rotation_duration * 1000);
    };
    const clearNextTimeout = () => {
      setIsAutoRotating(false);
      clearTimeout(timeout);
    };
    const handleMouseOver = () => {
      isMouseOver = true;
      clearNextTimeout();
    };
    const handleMouseOut = () => {
      isMouseOver = false;
      nextTimeout();
    };
    sliderRef.current.container.addEventListener('mouseover', handleMouseOver);
    sliderRef.current.container.addEventListener('mouseout', handleMouseOut);

    sliderRef.current.on('dragStarted', clearNextTimeout);
    sliderRef.current.on('animationStarted', nextTimeout);
    sliderRef.current.on('updated', nextTimeout);

    const startSlideshow: IntersectionObserverCallback = (entries) => {
      // If user doesn't like motion, don't start the slideshow
      if (entries[0].isIntersecting && !prefersReducedMotion) {
        nextTimeout();
        observer.unobserve(container);
      }
    };
    const observer = new IntersectionObserver(startSlideshow, { threshold: 0.5 });
    observer.observe(container);

    return () => {
      clearNextTimeout();

      if (!sliderRef.current) {
        return;
      }

      sliderRef.current.container.removeEventListener('mouseover', handleMouseOver);
      sliderRef.current.container.removeEventListener('mouseout', handleMouseOut);

      // remove event handlers
      sliderRef.current.on('dragStarted', clearNextTimeout, true);
      sliderRef.current.on('animationStarted', nextTimeout, true);
      sliderRef.current.on('updated', nextTimeout, true);
    };
  }, [auto_rotate, pages, rotation_duration]);

  const handleNext = useCallback(() => sliderRef.current?.next(), []);
  const handlePrev = useCallback(() => sliderRef.current?.prev(), []);

  const barWidth = isAutoRotating ? `100%` : 0;
  const barTransition = isAutoRotating
    ? `width linear ${rotation_duration * 0.95}s`
    : '';

  return (
    <>
      <div className="background" />

      <div ref={containerRefCallback} className="content keen-slider">
        {pages?.map((page, i) => (
          <CarouselPage
            key={i}
            lang={lang}
            page={page}
            prefersReducedMotion={prefersReducedMotion}
          />
        ))}

        {pages?.length && pages.length > 1 && (
          <>
            <button className="btn flex tight nav prev" onClick={handlePrev}>
              <img src="/images/chevron-left-white.svg" alt="Previous slide" />
            </button>
            <button className="btn flex tight nav next" onClick={handleNext}>
              <img src="/images/chevron-right-white.svg" alt="Next slide" />
            </button>

            <div className="position-indicator">
              {range(pages?.length).map((i) => (
                <button
                  key={i}
                  className={classNames('pos', {
                    active: activePageIndex === i,
                  })}
                  onClick={() => sliderRef.current?.moveToIdx(i, true)}
                  aria-label={`Go to slide ${i + 1}.`}
                />
              ))}
            </div>
          </>
        )}
      </div>

      <div className="bar" style={{ width: barWidth, transition: barTransition }} />

      {activePage && (
        <div className="live-region" aria-live="polite" aria-atomic="true">
          <p>
            Showing slide {activePageIndex + 1} of {pages?.length}.
          </p>

          {activePage.page_title && (
            <h2
              dangerouslySetInnerHTML={{
                __html: removeOuterTags(activePage.page_title.html),
              }}
            />
          )}
          {activePage.subtitle && (
            <h3
              dangerouslySetInnerHTML={{
                __html: removeOuterTags(activePage.subtitle.html),
              }}
            />
          )}
          {activePage.subsubtitle && (
            <p
              dangerouslySetInnerHTML={{
                __html: removeOuterTags(activePage.subsubtitle.html),
              }}
            />
          )}
          {activePage.carousel_image_alt && <p>{activePage.carousel_image_alt}</p>}
        </div>
      )}
    </>
  );
};

import { postJson } from '../util/fetch';

/**
 * Submits a contact request.
 *
 * @param recaptchaToken the ReCaptcha V3 validation token
 * @param data any form data to be submitted
 * @returns
 */
export const submitPublicContactFormRequest = (
  recaptchaToken: string,
  data: Record<string, any>,
) => postJson({ ...data, recaptchaToken }, '/api/contact');

import { AccessoryListSlice } from './accessory-list';
import { Austromat220Slice } from './austromat-220';
import { BannerSlice } from './banner';
import { CarouselSlice } from './carousel';
import { ContactFormSlice } from './contact-form';
import { ContentSlice } from './content';
import { CountdownSlice } from './countdown';
import { CustomMap } from './custom-map';
import { FeatureListSlice } from './feature-list';
import { IconListSlice } from './icon-list';
import { ImageSlice } from './image';
import { PartnerListSlice } from './partner-list';
import { PartnerMap } from './partner-map';
import { ProductListSlice } from './product-list';
import { QuoteSlice } from './quote';
import { ServiceSlice } from './service';
import { SplitList } from './split-list';
import { TimelineSlice } from './timeline';
import { TwoWayBannerSlice } from './two-way-banner';
import { GoldAustromatSlice } from './gold-austromat';

export const sliceMap: {
  [k: string]: React.FC<any> | React.ComponentClass<any>;
} = {
  accessory_list: AccessoryListSlice,
  austromat_220: Austromat220Slice,
  banner: BannerSlice,
  image: ImageSlice,
  carousel: CarouselSlice,
  contact: ContactFormSlice,
  content: ContentSlice,
  countdown: CountdownSlice,
  custom_map: CustomMap,
  feature_list: FeatureListSlice,
  icon_list: IconListSlice,
  partner_list: PartnerListSlice,
  partner_map: PartnerMap,
  product_list: ProductListSlice,
  quote: QuoteSlice,
  service: ServiceSlice,
  split_list: SplitList,
  timeline: TimelineSlice,
  two_way_banner: TwoWayBannerSlice,
  gold_austromat: GoldAustromatSlice,
};

import React from 'react';

import { ContentSlice as ContentSliceProps } from '../../types/graphql';

import '../../styles/slices/content.scss';

import { SliceProps } from './sliceprops';

export const ContentSlice: React.FC<SliceProps<ContentSliceProps>> = ({
  data: { body },
}) =>
  body?.html ? (
    <div className="container" dangerouslySetInnerHTML={{ __html: body.html }} />
  ) : null;

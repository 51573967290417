import classNames from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { usePrefersReducedMotion } from '../../hooks/dom';
import { QuoteSlice as QuoteSliceProps } from '../../types/graphql';

import { SliceProps } from './sliceprops';

import flame from '../../../static/images/dekema-logo-small.svg';

import '../../styles/slices/quote.scss';
import '../../styles/slices/playwright.scss';

export const QuoteSlice: React.FC<SliceProps<QuoteSliceProps>> = ({
  data: {
    portrait_image_alt,
    portrait_image,
    portrait_video,
    quote_author,
    quote_text,
    show_quotation_marks,
  },
}) => {
  const prefersReducedMotion = usePrefersReducedMotion();

  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const fadeInOffset = Number(portrait_video?.fade_in_offset ?? 0);
  const fadeDuration = fadeInOffset || 2;
  const [quoteVisible, setQuoteVisible] = useState(false);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    const c = new IntersectionObserver(
      (e) => {
        if (!e[0].isIntersecting) {
          return;
        }

        if (!prefersReducedMotion) {
          videoRef.current
            ?.play()
            .catch((err) =>
              console.warn(`Could not start playing quote video: ${err}`),
            );
        }

        if (!fadeInOffset || prefersReducedMotion) {
          setQuoteVisible(true);
        }

        c.disconnect();
      },
      { threshold: 0.5 },
    );

    c.observe(container);
    return () => c.disconnect();
  }, [videoRef, containerRef, prefersReducedMotion, setQuoteVisible]);

  const handleTimeUpdate = useCallback(() => {
    const video = videoRef.current;
    if (!video) {
      return;
    }

    const offset = video.duration - video.currentTime;
    if (fadeInOffset && offset < fadeInOffset) {
      setQuoteVisible(true);
    }
  }, [videoRef, setQuoteVisible]);

  return (
    <div className="container" ref={containerRef}>
      {!prefersReducedMotion &&
      (portrait_video?.portrait_video_mp4?.publicURL ||
        portrait_video?.portrait_video_webm?.publicURL) ? (
        <video
          ref={videoRef}
          className="portrait"
          muted
          loop
          onTimeUpdate={handleTimeUpdate}
          playsInline
        >
          {portrait_video?.portrait_video_mp4?.publicURL && (
            <source
              src={portrait_video.portrait_video_mp4.publicURL}
              type="video/mp4"
            />
          )}
          {portrait_video?.portrait_video_webm?.publicURL && (
            <source
              src={portrait_video.portrait_video_webm.publicURL}
              type="video/webm"
            />
          )}
          {portrait_video?.portrait_video_ogg?.publicURL && (
            <source
              src={portrait_video.portrait_video_ogg.publicURL}
              type="video/ogg"
            />
          )}
        </video>
      ) : portrait_image?.childImageSharp?.gatsbyImageData ? (
        <GatsbyImage
          className="portrait"
          image={portrait_image?.childImageSharp?.gatsbyImageData}
          alt={portrait_image_alt || ''}
          imgStyle={{ objectFit: 'contain' }}
        />
      ) : null}

      <img className="flame" src={flame} alt="DEKEMA Flame" />

      <blockquote
        className={classNames({
          'no-quotes': show_quotation_marks === false || !quote_author,
        })}
        style={{
          opacity: quoteVisible ? 1 : 0,
          transform: `translateY(${quoteVisible ? 0 : 50}px)`,
          transition: `opacity ${fadeDuration}s, transform ${fadeDuration}s`,
        }}
      >
        <div
          className="quote"
          dangerouslySetInnerHTML={{ __html: quote_text?.html ?? '' }}
        />
        {quote_author && <div className="author">– {quote_author}</div>}
      </blockquote>
    </div>
  );
};

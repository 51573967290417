import { RouteComponentProps } from '@reach/router';
import React, { useEffect, Suspense } from 'react';

import { startProgress, Progress } from './progress';

import '../styles/make-lazy.scss';

const LazyProgress: React.FC = () => {
  useEffect(() => {
    let progress: Progress;
    const timeout = setTimeout(() => {
      progress = startProgress();
    }, 500);

    return () => {
      clearTimeout(timeout);
      if (progress) {
        progress.done();
      }
    };
  }, []);

  return null;
};

// Needs to be `function` because of ambiguity with JSX
const MakeLazy = function <P>(
  loader: () => Promise<{ default: React.ComponentType<P> }>,
) {
  const Lazy = React.lazy(loader);

  const state = {
    render: (props, ref) => (
      <Suspense fallback={<LazyProgress />}>
        <Lazy ref={ref} {...(props as any)} />
      </Suspense>
    ),
  };

  const LazyWrapper = React.forwardRef((props: P & RouteComponentProps, ref) =>
    state.render(props, ref),
  );

  const loadAndReplace = () =>
    loader().then(({ default: Component }) => {
      // We replace the render function by one that does not render any `React.lazy`
      // or Suspense. This ensures there is never a visible flicker, even if the JS
      // has already been preloaded and parsed.

      state.render = (props, ref) => <Component ref={ref} {...props} />;
      return { default: Component };
    });
  (LazyWrapper as any).preload = loadAndReplace;

  return LazyWrapper as typeof LazyWrapper & { preload: typeof loadAndReplace };
};

export default MakeLazy;

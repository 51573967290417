import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { GoldAustromatSlice as GoldAustromatSliceProps } from '../../types/graphql';

import Button from '../button';
import Scroller from '../scroller';

import { SliceProps } from './sliceprops';

import '../../styles/slices/gold-austromat.scss';

type Props = SliceProps<GoldAustromatSliceProps>;

export const GoldAustromatSlice: React.FC<Props> = ({
  data: {
    badge_image,
    badge_image_alt,
    content,
    cta_link,
    cta_text,
    product_image,
    product_image_alt,
    top_banner_text,
  },
}) => (
  <>
    <div className="container">
      <div className="banner-image">
        {product_image?.childImageSharp?.gatsbyImageData && (
          <GatsbyImage
            alt={product_image_alt ?? ''}
            image={product_image.childImageSharp.gatsbyImageData}
          />
        )}

        {badge_image?.childImageSharp?.gatsbyImageData && (
          <GatsbyImage
            className="badge"
            alt={badge_image_alt ?? ''}
            image={badge_image.childImageSharp.gatsbyImageData}
          />
        )}
      </div>
      <div className="banner-content">
        <div
          className="text"
          dangerouslySetInnerHTML={{ __html: content?.html ?? '' }}
        />

        {cta_text && cta_link && <Button href={cta_link ?? '#'}>{cta_text}</Button>}
      </div>
    </div>
    <div className="banner-text">
      {Array(20)
        .fill(0)
        .map((_, i) => (
          <span key={i}>{top_banner_text}</span>
        ))}
    </div>
    <Scroller />
  </>
);
